import React from 'react';
import { Box, Text } from 'theme-ui';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { Props } from './types';
import DesktopLayout from './layouts/DesktopLayout';
import MobileLayout from './layouts/MobileLayout';
import { HighlightedText } from '@sprinklr/shared-lib/components/higlightedText';
import { RichText } from '@sprinklr/shared-lib';

const CxWiseSectionDiariesTemplate = (props: Props) => {
  const {
    title,
    description,
    topMargin,
    bottomMargin,
    backgroundColor = 'White',
    contentList = [],
    ctas,
    socialShareContent,
  } = props;

  const padding = { top: topMargin ?? 'NONE', bottom: bottomMargin ?? 'NONE' };
  const [pt, pb] = useContainerPadding(padding);

  const background =
    backgroundColor === 'White'
      ? '#fff'
      : 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.71deg, #0A0A14 0.13%, #052A3F 74.44%)';

  const textColor = backgroundColor === 'White' ? '#000' : '#fff';

  return (
    <Box sx={{ pt, pb, background }}>
      <HighlightedText
        sx={{
          maxWidth: '1000px',
          marginX: 'auto',
          textAlign: 'center',
          fontSize: ['36px', '40px', '48px'],
          lineHeight: ['44px', '50px', '58px'],
          fontWeight: 800,
          marginBottom: '0px',
          color: textColor,
          textTransform: 'uppercase',
        }}
        as="h2"
      >
        {title}
      </HighlightedText>
      <Text
        sx={{
          textAlign: 'center',
          marginInline: '84px',
          p: {
            fontWeight: 400,
            color: textColor,
          },
        }}
        as="div"
      >
        <RichText richTextObject={description} />
      </Text>
      <DesktopLayout
        storyList={contentList}
        cta={ctas[0]}
        socialShareContent={socialShareContent}
      />
      <MobileLayout
        storyList={contentList}
        cta={ctas[0]}
        socialShareContent={socialShareContent}
      />
    </Box>
  );
};

export default CxWiseSectionDiariesTemplate;
