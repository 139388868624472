/** @jsx jsx */
import React, { useState } from 'react';
import { Box, Text, jsx } from 'theme-ui';
import { CarouselSlider } from '@sprinklr/shared-lib/components/carouselSlider';
import {
  CallToAction,
  ImageBlock,
  RichText,
  SocialShare,
} from '@sprinklr/shared-lib';
import { keyframes } from '@emotion/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CxStory, LayoutProps, Props } from '../types';
import { Icon } from '@sprinklr/shared-lib/components/icon';

const setCarouselStyles = (
  isDraggable,
  shouldPause,
  autoplay,
  slideCount,
  slidesToShowDesktop,
  slidesToShowTablet,
  arrows,
  showBackground,
  alignment,
) => {
  const showBoxShadow = !showBackground;
  const expand = keyframes`
    from {
      transform: translate(-100%);
    }
    to {
      transform: translate(0);
    }
  `;

  return {
    display: ['block', 'none'],
    backgroundColor: showBackground ? '#f1f0f0' : undefined,
    paddingY: showBackground ? ['30px', null, '50px'] : undefined,
    paddingX: showBackground ? ['30px', null, '150px'] : undefined,
    '.slick-slider': {
      // Magic number to not hide top box-shaddow
      paddingTop: showBoxShadow ? '15px' : '0px',
      // Magic number to not hide bottom of box-shaddow if there is no controls underneath
      paddingBottom: [
        slideCount === 1 ? '15px' : 0,
        slideCount <= slidesToShowTablet ? '15px' : 0,
        null,
        slideCount <= slidesToShowDesktop ? '15px' : 0,
      ],
    },
    '.slick-list': {
      ...(arrows
        ? {
            boxShadow: showBoxShadow
              ? '0px 2px 4px 0px rgba(0, 0, 0, 0.2)'
              : undefined,
            borderRadius: '16px',
          }
        : {
            overflow: 'visible',
          }),
      '.slick-track': {
        // Force slides to have same height: https://stackoverflow.com/a/53131996
        display: 'flex',
        '.slick-slide': {
          // Fake 32px columns: https://github.com/kenwheeler/slick/issues/582#issuecomment-61882540
          ...(arrows
            ? {}
            : {
                px: [`calc(32px / 2)`],
              }),
          height: 'inherit',
          '&>*': {
            height: '100%',
          },
          '@media (pointer: fine)': {
            // Only buttons & anchors currently supported in carousel, if other tab elements supported expand this selector
            '&[aria-hidden=true] > * a, &[aria-hidden=true] > * button': {
              visibility: 'hidden',
              transition: 'visibility 0.5s linear',
            },
          },
          display: alignment === 'CENTER' ? 'flex' : undefined,
          justifyContent: alignment === 'CENTER' ? 'center' : undefined,
        },
      },
    },
    '.slick-prev': {
      top: 'calc(50% - 24px)',
      '&::before': {
        content: 'none',
      },
    },
    '.slick-next': {
      top: 'calc(50% - 24px)',
      '&::before': {
        content: 'none',
      },
    },
    '.slick-dots': {
      position: 'relative',
      bottom: 0,
      height: ['48px', '54px', null, '60px'],
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'flex-end',
      // Negative margin and width calc() accounts for the mx between li
      mx: ['-4px', '-8px'],
      width: ['calc(100% + 8px)', 'calc(100% + 16px)'],
      px: [`calc(32px / 2)`],
      li: {
        width: '100px',
        maxWidth: '100px',
        // Height on li/button and mt on ::before is to create an accessible click area while visually displaying a 6px tall bar
        height: ['48px', null, null, '6px'],
        mx: ['4px', '8px'],
        button: {
          position: 'relative',
          width: '100%',
          height: ['48px', null, null, '6px'],
          borderRadius: '3px',
          overflow: 'hidden',
          p: 0,
          // ::before and ::after pseudo-elements necessary for progress bar styling
          '&::before': {
            opacity: 1,
            content: "''",
            background: 'lightGrey',
            height: '6px',
            width: '100%',
            borderRadius: '3px',
            mt: ['42px', null, null, '0px'],
          },
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 1,
            content: "''",
            background: '#000',
            height: '6px',
            width: '100%',
            borderRadius: '3px',
            mt: ['42px', null, null, '0px'],
            transformOrigin: '0%',
          },
        },
        '&.slick-active': {
          'button::after': {
            // Magic number for durationis autoplaySpeed + speed in CarouselSlider settings
            animation: [
              null,
              null,
              null,
              autoplay && `${expand} 8000ms linear`,
            ],
            animationPlayState: [null, null, null, shouldPause && 'paused'],
            transition: '.2s background',
          },
        },
        '&.slick-active ~ li': {
          'button:not(:hover)::after': {
            background: 'transparent',
          },
          'button:focus-visible::before': {
            transition: '.2s background',
            background: '#185AD2',
          },
        },
        'button:hover::after': {
          transition: '.2s background',
          background: '#185AD2',
        },
        'button:focus-visible::after': {
          transition: '.2s background',
          background: '#185AD2',
        },
      },
    },
  };
};

function AvatarCarousel({
  children,
  setActiveStoryIndex,
}: {
  children: React.ReactNode;
  setActiveStoryIndex: (index: number) => void;
}) {
  return (
    <Box
      sx={setCarouselStyles(false, false, false, 4, 0, 3, true, true, 'CENTER')}
    >
      <CarouselSlider
        arrows
        slidesToShowTablet={3}
        slidesToScroll={1}
        slidesToShowMobile={3}
        arrowVariant="ALTERNATE"
        showDots
        onChange={index => {
          setActiveStoryIndex(index);
        }}
      >
        {children}
      </CarouselSlider>
    </Box>
  );
}

function MainSection({ storyList, cta, socialShareContent }: LayoutProps) {
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  const activeStory = storyList[activeStoryIndex];

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        zIndex: 0,
        paddingTop: '36px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 'calc(100% - 110px)',
          backgroundColor: '#f1f1f1',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          backgroundColor: '#2E3841',
          color: '#ffffff',
          paddingBlock: '60px 40px',
          paddingInline: '64px 8px',
          borderRadius: '100em 0 0 100em',
          width: 'calc(100% - 16px)',
          marginInlineStart: 'auto',
        }}
      >
        <Box
          sx={{
            color: '#00BAE9',
            height: '52px',
            position: 'absolute',
            top: '-36px',
            right: '36px',
          }}
        >
          <Icon
            iconSx={{
              height: '100%',
            }}
            iconName="quote1"
          />
        </Box>
        <Box
          sx={{
            p: {
              fontWeight: 800,
              fontSize: '32px',
              lineHeight: '34px',
              color: '#ffffff',
            },
            margin: 0,
            marginBottom: '44px',
          }}
        >
          <RichText richTextObject={activeStory.highlight} />
        </Box>
        <Box
          sx={{
            position: 'relative',
            left: '100px',
            width: 'calc(100% - 100px)',
          }}
        >
          <Text
            as="p"
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '22px',
              letterSpacing: '2.5px',
              margin: 0,
            }}
          >
            {activeStory.author.title},
          </Text>
          <Text
            as="p"
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '22px',
              letterSpacing: '2.5px',
              margin: 0,
            }}
          >
            {activeStory.author.description}
          </Text>
        </Box>
        <ImageBlock
          image={activeStory.author.images[0]}
          imageSx={{
            display: 'block',
            width: '152px',
            height: '152px',
            borderRadius: '50%',
            position: 'absolute',
            left: 0,
            bottom: '-44px',
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: '84px',
          marginInline: '52px 72px',
          '& > p': {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '25px',
          },
        }}
      >
        <RichText richTextObject={activeStory.story} />
      </Box>
      <AvatarCarousel setActiveStoryIndex={setActiveStoryIndex}>
        {storyList.map(content => (
          <ImageBlock
            key={content.author.title}
            image={content.author.images[0]}
            imageSx={{
              height: '100px',
              width: '100px',
              borderRadius: '50%',
              margin: 'auto',
            }}
          />
        ))}
      </AvatarCarousel>
      <Box
        sx={{
          marginBlock: '18px 40px',
        }}
      >
        <CallToAction {...cta} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          paddingBottom: '36px',
        }}
      >
        <Box
          sx={{
            fontWeight: ' 700',
            fontSize: '14px',
            lineHeight: '17px',
            color: '#A9A9A9',
          }}
        >
          share:
        </Box>
        <SocialShare content={socialShareContent.socialShareContent} />
      </Box>
    </Box>
  );
}

const MobileLayout = ({ storyList, cta, socialShareContent }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: ['block', 'none'],
      }}
    >
      <MainSection
        storyList={storyList}
        cta={cta}
        socialShareContent={socialShareContent}
      />
    </Box>
  );
};

export default MobileLayout;
