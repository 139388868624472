/** @jsx jsx */
import {
  CallToAction,
  ImageBlock,
  RichText,
  SocialShare,
} from '@sprinklr/shared-lib';
import React, { useState } from 'react';
import { Box, Text, jsx, ThemeUIStyleObject, Flex } from 'theme-ui';
import { CxStory, LayoutProps, Props } from '../types';
import ImageElement from '@sprinklr/shared-lib/components/listView/ImageElement';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useTranslation } from 'react-i18next';

const SHOW_MORE_TEXT = 'Show More';

const avatarStyles: ThemeUIStyleObject[] = [
  {
    width: '50%',
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
  },
  {
    width: '22%',
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-50%, -50%)',
  },
  {
    width: '22%',
    position: 'absolute',
    top: '50%',
    left: '15%',
    transform: 'translate(-50%, -50%)',
    flexDirection: 'row-reverse',
  },
  {
    width: '22%',
    position: 'absolute',
    top: '75%',
    left: '25%',
    transform: 'translate(-75%, -50%)',
  },
];

function AvatarSection({
  storyList,
  cta,
  activeStoryIndex,
  setActiveStoryIndex,
}: {
  storyList: LayoutProps['storyList'];
  cta: LayoutProps['cta'];
  activeStoryIndex: number;
  setActiveStoryIndex: (index: number) => void;
}) {
  return (
    <Box
      sx={{
        width: '50%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          aspectRatio: '1 / 1',
          marginTop: '32px',
        }}
      >
        {storyList.map((content, index) => {
          const authorTitle = content?.author?.title || '';
          const authorFirstName = authorTitle.split(' ')[0];
          const authorNamePossessive = `${authorFirstName}'s`;

          if (index === activeStoryIndex) {
            return (
              <button
                sx={{
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                  border: 'none',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  ...avatarStyles[
                    (index - activeStoryIndex + storyList.length) %
                      storyList.length
                  ],
                }}
                onClick={() => setActiveStoryIndex(index)}
              >
                <ImageBlock
                  key={content.author.title}
                  image={content.author.images[0]}
                />
              </button>
            );
          }
          return (
            <Flex
              sx={{
                alignItems: 'center',
                gap: '10px',
                ...avatarStyles[
                  (index - activeStoryIndex + storyList.length) %
                    storyList.length
                ],
              }}
            >
              <Box
                sx={{
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                  fontSize: '14px',
                  lineHeight: '12px',
                  flexShrink: 0,
                }}
              >
                <Box as="p" sx={{ color: 'primary', fontWeight: 800 }}>
                  {authorNamePossessive}
                </Box>
                <Box as="p">story</Box>
              </Box>

              <Box
                onClick={() => setActiveStoryIndex(index)}
                sx={{
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                  border: 'none',
                  width: '100%',
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                }}
                as="button"
              >
                <ImageBlock
                  key={content.author.title}
                  image={content.author.images[0]}
                  imageSx={{
                    borderRadius: '50%',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    border: '2px solid',
                    borderColor: 'transparent',

                    '&:hover': {
                      borderColor: 'primary',
                    },
                  }}
                />
              </Box>
            </Flex>
          );
        })}
        <Box
          sx={{
            width: '75%',
            position: 'absolute',
            top: '75%',
            left: '55%',
            transform: 'translate(-50%, 32px)',
          }}
        >
          <CallToAction {...cta} />
        </Box>
        <div
          style={{
            width: '10%',
            aspectRatio: '1 / 1',
            backgroundColor: '#36647D',
            borderRadius: '50%',
            position: 'absolute',
            top: '60%',
            left: '30%',
          }}
        />
        <div
          style={{
            width: '10%',
            aspectRatio: '1 / 1',
            backgroundColor: '#70BF54',
            borderRadius: '50%',
            position: 'absolute',
            top: '40%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <div
          style={{
            width: '3%',
            aspectRatio: '1 / 1',
            backgroundColor: '#70BF54',
            borderRadius: '50%',
            position: 'absolute',
            top: '75%',
            left: '70%',
            transform: 'translateY(84px)',
          }}
        />
      </Box>
    </Box>
  );
}

function MainSection({
  storyList,
  activeStoryIndex,
  socialShareContent,
}: {
  storyList: CxStory[];
  activeStoryIndex: number;
  setActiveStoryIndex: (index: number) => void;
  socialShareContent: { socialShareContent: string };
}) {
  const activeStory = storyList[activeStoryIndex];
  const { t } = useTranslation();

  const [isStoryExpanded, setIsStoryExpanded] = useState(false);

  return (
    <Box
      sx={{
        width: '50%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#2E3841',
          color: '#ffffff',
          paddingBlock: '60px 40px',
          paddingInline: '108px 72px',
          position: 'absolute',
          right: 0,
          top: 0,
          transform: 'translateY(-50%)',
          borderRadius: '100em 0 0 100em',
          width: 'calc(100% + 108px)',
        }}
      >
        <Box
          sx={{
            color: '#00BAE9',
            height: '84px',
            position: 'absolute',
            top: '-54px',
            right: '64px',
          }}
        >
          <Icon
            iconSx={{
              height: '100%',
            }}
            iconName="quote1"
          />
        </Box>
        <Box
          sx={{
            p: {
              fontWeight: 800,
              fontSize: '32px',
              lineHeight: '40px',
              color: '#ffffff',
            },
            margin: 0,
            marginBottom: '16px',
          }}
        >
          <RichText richTextObject={activeStory.highlight} />
        </Box>
        <Box
          sx={{
            position: 'relative',
            left: 0,
            width: 'initial',
          }}
        >
          <Text
            as="p"
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '2.5px',
              margin: 0,
            }}
          >
            {activeStory.author.title},
          </Text>
          <Text
            as="p"
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '2.5px',
              margin: 0,
            }}
          >
            {activeStory.author.description}
          </Text>
        </Box>
        <ImageElement
          srcData={activeStory.author.images[0]}
          imageSx={{
            display: 'none',
            width: '152px',
            height: '152px',
            borderRadius: '50%',
            position: 'absolute',
            left: 0,
            bottom: '-44px',
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: '250px',
        }}
      >
        <Box
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '25px',
            marginInline: '0 128px',
            marginBlock: '16px 56px',
            '& > :nth-child(n+3)': {
              display: isStoryExpanded ? undefined : 'none',
            },
            '& > :last-child': {
              display: isStoryExpanded ? undefined : 'inline',
            },
          }}
        >
          <RichText richTextObject={activeStory.story} />
          {!isStoryExpanded ? (
            <button
              onClick={() => {
                setIsStoryExpanded(true);
              }}
              sx={{
                border: 'none',
                fontFamily: 'inherit',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '25px',
                color: '#00BAE9',
                cursor: 'pointer',
                paddingInline: 0,
                backgroundColor: 'transparent',
              }}
            >
              {`${t(SHOW_MORE_TEXT)}...`}
            </button>
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '44px',
          }}
        >
          <Box
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: 700,
            }}
          >
            share:
          </Box>
          <SocialShare content={socialShareContent.socialShareContent} />
        </Box>
      </Box>
    </Box>
  );
}

function Body({ storyList, cta, socialShareContent }: LayoutProps) {
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        background: '#F1F1F1',
        minHeight: '654px',
      }}
    >
      <AvatarSection
        cta={cta}
        storyList={storyList}
        activeStoryIndex={activeStoryIndex}
        setActiveStoryIndex={setActiveStoryIndex}
      />
      <MainSection
        storyList={storyList}
        activeStoryIndex={activeStoryIndex}
        setActiveStoryIndex={setActiveStoryIndex}
        socialShareContent={socialShareContent}
      />
    </div>
  );
}

const DesktopLayout = ({ storyList, cta, socialShareContent }: LayoutProps) => {
  return (
    <Box
      sx={{
        marginTop: '180px',
        display: ['none', 'block'],
      }}
    >
      <Body
        storyList={storyList}
        cta={cta}
        socialShareContent={socialShareContent}
      />
    </Box>
  );
};

export default DesktopLayout;
